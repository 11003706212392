import {
  StoreName,
  withError,
  withLoaded,
  withLoading,
} from 'src/app/core/store/store.config';

import { createStore } from '@ngneat/elf';
import {
  withActiveId,
  withEntities,
  withUIEntities,
} from '@ngneat/elf-entities';

import { Scenario, ScenarioUI } from './scenario.model';

export const scenarioStore = createStore(
  { name: StoreName.Scenario },
  withEntities<Scenario, 'uuid'>({ idKey: 'uuid' }),
  withUIEntities<ScenarioUI, 'uuid'>({ idKey: 'uuid' }),
  withActiveId(),
  withLoading(),
  withLoaded(),
  withError(),
);
